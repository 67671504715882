import styled from '@emotion/styled';
import Checkbox from '@mui/material/Checkbox';
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import type { SelectChangeEvent } from '@mui/material/Select';
import Select from '@mui/material/Select';
import type { JSX } from 'react';
import { useId, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledSelectIcon } from '../../../../base/stihl-material-ui/components/stihl-select/stihl-select';
import { stihlColor } from '../../../../base/stihl-material-ui/theme/stihl-style-guide';
import type { DeviceModel, DeviceType } from '../../../device/model';
import { typeModelMatcher } from '../overview-items/utils/device-management.const';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    sx: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      inlineSize: 200,
      backgroundColor: `${stihlColor.white}`,
      '& li.Mui-selected': {
        backgroundColor: `${stihlColor.greyMid}`,
      },
      '& li.Mui-selected:hover': {
        backgroundColor: `${stihlColor.greyLight}`,
      },
    },
  },
};

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-inline-size: 12rem;
`;

const DeviceTypeFilter = ({
  deviceModel,
  deviceTypeCallback,
  shouldReset,
}: {
  deviceModel: DeviceModel;
  deviceTypeCallback: (filterValues: DeviceType[]) => void;
  shouldReset: boolean;
}): JSX.Element => {
  const { t } = useTranslation();
  const [deviceType, setDeviceType] = useState<DeviceType[]>([]);

  const deviceTypesMenuItems = typeModelMatcher[deviceModel];

  useEffect(() => {
    if (shouldReset) {
      setDeviceType([]);
    }
  }, [shouldReset]);

  const handleChange = (event: SelectChangeEvent<typeof deviceType>): void => {
    const {
      target: { value },
    } = event;
    const newDeviceType =
      typeof value === 'string' ? (value.split(',') as DeviceType[]) : value;
    setDeviceType(newDeviceType);
    deviceTypeCallback(newDeviceType);
  };

  const labelId = useId();

  return (
    <StyledWrapper>
      <InputLabel
        variant="standard"
        id={labelId}
        sx={{ color: stihlColor.black }}
      >
        {t('filterLabel.deviceType')}
      </InputLabel>
      <Select
        multiple
        labelId={labelId}
        value={deviceType}
        onChange={handleChange}
        input={<OutlinedInput sx={{ color: 'black' }} />}
        renderValue={(selected) => selected.join(', ')}
        MenuProps={MenuProps}
        IconComponent={StyledSelectIcon}
        sx={{
          blockSize: '3rem',
          marginTop: '0.25rem',
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: `${stihlColor.black}`,
          },
        }}
        data-testid="deviceTypeFilter"
      >
        {deviceTypesMenuItems.map((type) => (
          <MenuItem key={type.label} value={type.value}>
            <Checkbox checked={deviceType.includes(type.value)} />
            <ListItemText primary={t(type.label)} />
          </MenuItem>
        ))}
      </Select>
    </StyledWrapper>
  );
};

export default DeviceTypeFilter;
